.clients-in-container {
  position: relative;
  margin-bottom: 100px;

  .action-buttons {
    position: fixed;
    bottom: 40px;
    right: 100px;
    display: flex;
    gap: 8px;
    z-index: 1000;

    .p-button {
      width: 120px;

      &-label {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:disabled {
        cursor: not-allowed !important;
        pointer-events: all !important;
      }
    }
  }

  .datatable-header {
    border: none;
    padding-right: 0;
    padding-left: 0;
    background-color: #fafbfb;

    .datatable-header-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      // &:first-of-type {
      //   margin-bottom: 6px;
      //   padding-bottom: 6px;
      //   border-bottom: 1px solid #f1f2f4;
      // }

      &:last-of-type {
        margin-bottom: 12px;
      }
    }

    .filters {
      width: 100%;
    }

    .data-count {
      margin-top: 8px;
      margin: 5px 2px 10px;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #29394D;
      span {
        font-weight: 500;
      }
    }

    .display-info-buttons {
      display: flex;
      justify-content: flex-end;

      .display-info-button {
        background-color: white;
        border: 1px solid #3c4e64;
        padding: 6px 20px;
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
        color: #3c4e64;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        border-radius: 0;
      }

      .display-info-button:hover {
        background-color: #f1f1f1;
      }

      .display-info-button.active {
        background-color: #3c4e64;
        color: #fff;
        border: 1px solid #3c4e64;
      }

      .display-info-button:first-of-type {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-right: none;
      }

      .display-info-button:last-of-type {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-left: none;
      }
    }
  }

  .p-datatable-loading-overlay.p-component-overlay {
    background-color: rgba($color: #000000, $alpha: 0.2);
    border-radius: 12px;
  }

  .p-paginator {
    button {
      width: fit-content;
    }
  }
}

.comments-modal-dialog {
  width: 687px;

  .comments-textarea {
    width: 100%;
  }
}

.clients-in-datatable {
  .p-datatable-frozen-tbody {
    tr {
      background-color: #f4f2f2;

      td {
        font-size: 13px !important;
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
    .total-title {
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .p-datatable-thead .datatable-column {
    height: 48px;
    padding: 6px;
    font-size: 11px;
    line-height: 14px;
    background-color: #3c4e64;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin: 0px 1px;
    margin-left: 10px;

    &:hover {
      .p-highlight:hover  {
        background-color: #3c4e64;
        color: white;
      }
    }

    &:first-of-type {
      border-top-left-radius: 8px;
    }

    &:last-of-type {
      border-top-right-radius: 8px;
    }
  }

  .p-sortable-column, .p-sortable-column.p-highlight {
    background-color: #3c4e64;
    color: #fff;
    border: none;

    .p-sortable-column-icon {
      color: white;
    }

    &:not(.p-highlight):not(.p-sortable-disabled):hover {
      background-color: #3c4e64;
      color: #fff;
      border: none;
    }

    &:not(.p-sortable-disabled):hover {
      background-color: #3c4e64;
      color: #fff;
      border: none;

      .p-sortable-column-icon {
        color: white;
      }
    }

    &:hover {
      background-color: #3c4e64;
      color: #fff;
      border: none;

      .p-sortable-column-icon {
        color: white;
      }
    }
  }

  .datatable-column {
    font-size: 14px;
    gap: 10px;

    &:first-of-type {
      border-top-left-radius: 12px;
    }

    &:last-of-type {
      border-top-right-radius: 12px;
    }
  }

  .datatable-cell-input {
    padding: 4px 6px;
    font-size: 12px;
  }

  .p-datatable-tbody tr {
    .client-name-cell {
      display: flex;
      justify-content: space-between;

      &-data {
        display: flex;
        flex-direction: column;

        .client-name-text {
          text-overflow: ellipsis;
          margin-bottom: 4px;
          font-family: "Roboto";
          font-weight: 500;
          font-size: 16px;
          line-height: 160%;
          display: flex;
          align-items: center;
          color: #29394D;
          text-transform: uppercase;
        }

        .client-name-info {
          display: flex;
          align-items: center;
          font-family: "Roboto";
          font-weight: 500;
          font-size: 12px;
          line-height: 160%;
          display: flex;
          align-items: center;
          color: #6D7F92;
        }
      }
      .client-name-icons {
        visibility: hidden;
        width: 30px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 12px;
        transition: visibility .3 linear;

        .icon {
          cursor: pointer;
        }
      }
    }

    .centered-cell {
      display: block;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      .client-name-icons {
        visibility: visible;
      }
    }
  }

  .cell-datepicker-input {
    background-color: #f1f2f4;
    color: #707f92;
    border: none;
    border-radius: 8px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }

  .cell-switch-input {
    width: fit-content;
    &-checked {
      background-color: #3c4e64;
    }
  }

  .cell-manager {
    // max-width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-name {
      text-wrap: wrap;
      text-align: left;
    }
  }

  .cell-input-field {
    // max-width: 160px;

    .input {
      background-color: #f1f2f4;
      color: #707f92;
      border: none;
      border-radius: 8px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
    }
  }

  .cell-nine-months {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    .cell-input-field {
      width: 100%;
    }

    &-volumetry {
      display: flex;
      gap: 6px;
      width: 100%;

      &-item {
        width: 33.333%;

        &:first-of-type {
          text-align: left;
        }

        &:last-of-type {
          text-align: right;
        }
      }

      span {
        display: inline-block;
        font-weight: bold;
        margin-right: 2px;
      }
    }
  }
}
