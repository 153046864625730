.off-canvas-filter {
  position: relative;
  overflow: hidden;
  .filter_tab_btn {
    position: relative;
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: "";
      position: absolute;
      top: -2px;
      right: 0;
      width: 10px;
      height: 10px;
      background-color: #f1c40f;
      border-radius: 50%;
      border: 2px solid #f3faff;
      transition: background-color 0.3s ease;
    }
  }

  .filter-tabs-container {
    height: 100%;
    width: 350px;
    position: fixed;
    z-index: 1000;
    top: 0;
    right: -350px;
    background-color: #fff;
    overflow-x: hidden;
    padding: 20px;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    gap: 30px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition: right 0.3s ease;

    &-header {
        display: flex;
        gap: 10px;
        align-items: flex-start;
        justify-content: space-between;
        font-style: normal;
        font-size: 14px;

        span {
            font-size: 12px;
        }

        button {
            border: none;
            background: transparent;
            cursor: pointer;

            svg * {
                fill: black !important;
            }
        }
    }

    &-items {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      overflow-y: auto;
      height: calc(100% - 70px);

      .filter-tab {
        background: #ffffff;
        border: 0;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        width: 98%;
        border-radius: 5px;
        padding: 14px 0px;
        cursor: pointer;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-size: 14px;
        text-align: center;
        // letter-spacing: 0.02em;
        color: #18a0fb;
        transition: 0.3s ease-in-out;

        &:hover {
          background: #18a0fb;
          color: #ffffff;
        }
      }
      .filter-tab_active {
        color: #ffffff;
        background: #18a0fb;
      }
    }
  }

  .open {
    right: 0;
  }

  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
}
