.colomn {
  display: flex;
  align-items: center;
  justify-content: none;
  .labled-tag {
    span {
      background: rgb(255, 255, 255);
      font-size: 12px;
      height: 50px;
    }
    div {
      border-left: none;
      font-family: Roboto;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: #6d7f92;
      height: 28px;
      width: 72px;
      border-radius: 5px;
      padding: 6px 5px;
      background: #f1f2f4;
      text-align: end;
      border-left: none;
      justify-content: flex-end;
    }
  }
  .tag {
    margin: 0 auto;
  }
  .labled-tag {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .tag {
      margin: 15px 0px 0px;
      // padding-left: 5px;
    }
  }
  .no-border {
    border: 0px;
    width: 150px;
    .tag {
      border: 0px;
      padding: 0px;
      & > div {
        margin: 0px;
      }
    }
  }
}

.vol_label {
  position: relative;
  z-index: 0;
}

.vol_label::before {
  content: "";
  position: absolute;
  top: 50%;
  right: -6.5px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  border-left: 6px solid #6d7f9250;
  pointer-events: none;
}

.clients_panel {
  position: absolute;
  left: 0px;
  margin-top: -5.5px;
  padding: 26px 20px;
  background-color: #fff;
  width: 300px;
  border: 1px solid #dfdfdf;
  border-left: 0;
  border-right: 0;
  // box-shadow: 7px 8px 15px #00000040;

  .expand_arrow {
    position: absolute;
    left: 50%;
    bottom: -12.5px;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 5px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
}

.prev_arrow,
.next_arrow {
  position: absolute;
  top: calc(50% - 40px);
  z-index: 3;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.prev_arrow {
  left: 260px;
}

.next_arrow {
  right: 0;
  transform: rotate(180deg);
}

.date-range {
  width: 325px;
  display: flex;
  height: 100%;
  align-items: center;
  background: #f1f2f4;
  border-radius: 30px;
  padding: 0px 15px;
  margin-left: 10px;
  svg {
    margin-right: 9px;
  }
  > div {
    width: 115px;
    padding: 0px 10px;
  }
  .start-date {
    border-right: 1px solid #e1e4e8;
    margin-right: 10px;
    padding-right: 4px;
  }
  .p-calendar {
    width: 115px;
  }

  .p-calendar .p-inputtext {
    background: #f1f2f4;
    border: none;
    height: 28px;

    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 0.02em;
    border-radius: 50px;
    color: #6d7f92;

    &:hover {
      background: #e1e4e8;
    }
    &:focus {
      background: #e1e4e8;
      border-color: none;
      box-shadow: none;
    }
  }
  &_action {
    height: 16px;
    width: 16px !important;
    border-radius: 50%;
    background: #6d7f92;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
    margin-left: 9px;
    cursor: pointer;
    svg {
      margin: 0px;
    }
  }
  .calendar-fill {
    .p-inputtext {
      background: #6d7f92;
      color: #fff;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.24px;
      &:hover {
        background: #6d7f92;
        cursor: pointer;
      }
    }
  }
  &_fill {
    background: #e1e4e8;
  }
}
.separator {
  width: 1.3px;
  height: 30px;
  background-color: #e1e4e8;
  margin: 0 15px;
}
.download-button {
  background: #06d9b1;
  box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  width: 172px;
  height: 44px;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  // margin-left: auto;
  cursor: pointer;
}

.form-control {
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 15px;

  .field-control {
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 7px;

    .label {
      color: #6d7f92;
    }

    .field {
      width: 100%;
      border: 1px solid #6d7f92;
      padding: 5px;
    }
  }
}

.work-hr-progress {
  position: relative;

  .divider {
    width: 100%;
    height: 1px;
    border-radius: 5px;
    background-color: #6d7f9250;
  }

  .avatar-rotate {
    animation: rotate-animation 0.3s ease-in-out forwards;
    background-color: #6d7f92;
    transition: background-color 0.3s ease-in-out;
  }

  @keyframes rotate-animation {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(20deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  .progress {
    position: absolute;
    top: 120%;
    left: 5px;
    width: 370px;
    max-height: 0;
    visibility: hidden;
    overflow: hidden;
    background-color: #fff;
    color: #29394d;
    border-radius: 5px;
    font-size: 15px;
    border: 1px solid #dfdfdf;
    z-index: 999;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

    p {
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 5px;
      text-transform: capitalize;
      color: #6d7f92;
    }

    .progress-bar {
      width: 100%;
      height: 5px;
      background-color: #6d7f9270;
      border-radius: 5px;
      margin-top: 10px;

      & > div {
        background-color: #06d9b1;
        height: 100%;
        border-radius: 5px;
      }
    }

    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
      font-weight: normal;

      span {
        background-color: #6d7f9230;
        padding: 7px;
        border-radius: 5px;
        color: #29394d;
        width: 40%;
        text-align: center;
        position: relative;

        // triangle before
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: -50%;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 5px solid #6d7f92;
        }
      }
    }

    .temp-absence {
      background-color: #e74c3c10;
      padding: 5px 7px;
      border-radius: 3px;
      color: #e74c3c;
    }
  }

  .progress-visible {
    visibility: visible;
    height: auto;
    max-height: 500px;
    padding: 15px;
    opacity: 1;
    animation: slide-animation 0.4s ease-in-out forwards;
  }

  @keyframes slide-animation {
    0% {
      max-height: 0;
      padding: 0 10px;
      opacity: 0;
    }
    100% {
      max-height: 500px;
      padding: 10px;
      opacity: 1;
    }
  }
}

.sidebar_body_content {
  padding: 15px 0;
}

.sidebar_footer {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  color: #29394d;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  background-color: #E1E4E850;
  border-top: 1px solid #E1E4E8;

  .print_invoice {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    gap: 40px;
    height: 44px;
    background-color: #fff;
    border: 1px solid #6d7f9250;
    border-radius: 7px;
    padding: 25px 30px;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      & > div {
        display: flex;
        flex-direction: column;
        gap: 2px;
      }

      strong {
        font-size: 12px;
        font-weight: 500;
        margin-bottom: -10px;
      }

      span {
        font-size: 10px;
        font-weight: 500;
        color: #6d7f92;
      }
    }

    button {
      background-color: transparent;
      border: 0;
      padding: 0;
      margin: 0;
      cursor: pointer;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        font-size: 12px;
      }
    }

    .invoice-edit {
      position: absolute;
      top: -7px;
      right: -7px;
      background-color: #fff;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      border: 1px solid #6d7f9250;
    }
  }
}

.invoice-pdf-iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: #29394d66;
  display: flex;

  .icon-close-iframe {
    position: absolute;
    top: 4%;
    right: 4%;
    font-size: 24px;
    color: #29394d;
    background-color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    z-index: 10000;
  }
}
