$red-color: #d50000;
$green-color: #00a000;
$orange-color: #d8a200;

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.content {
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .info_icon {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

.deposit_select_view {
  margin: 10px;
  align-self: flex-end;
}

.status_cercle {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  vertical-align: middle;

  &_red {
    background-color: $red-color;
  }

  &_green {
    background-color: $green-color;
  }

  &_orange {
    background-color: $orange-color;
  }
}

.tabs {
  width: 100%;
}
