@use "../../../styles/base/variables";

.cp_container {
  background-color: variables.$white;
  border: 1px solid variables.$athens-gray;
  border-radius: 5px;
  width: 100%;

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;

    h2 {
      color: variables.$level-0-color;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    p {
      color: variables.$slate-gray;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &_actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
    }
  }

  &_binome {
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    background-color: variables.$athens-gray;
    cursor: pointer;
    flex: 1 0 0;
    position: relative;
    min-width: 200px;
    box-sizing: border-box;

    &:hover {
      background-color: variables.$white;
      border-bottom: 2px solid variables.$dodger-blue;

      .cp_container_binome_details {
        &_bar {
          & > div {
            background-color: variables.$dodger-blue !important;
          }
        }
        & > div {
          color: variables.$dodger-blue;
        }
      }
    }

    &_avatars {
      text-align: center;
      justify-content: flex-start;
      display: flex;
      align-items: center;
      gap: 5px;

      .active_avatar {
        transform: scale(1);
        filter: grayscale(0);
      }

      .inactive_avatar {
        transform: scale(0.7);
        filter: grayscale(100%);
        opacity: 0.8;
        transition: transform 0.3s, filter 0.3s;
      }

      .avatar {
        position: relative;
        z-index: 5;

        &_exist {
          width: 34px;
          height: 34px;
          border: 1px white solid;
          border-radius: 50%;
          overflow: hidden;
          cursor: pointer;
          z-index: 5;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        &_not_exist {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 34px;
          height: 34px;
          font-size: 12px;
          font-weight: 600;
          color: white;
          border: 1px white solid;
          border-radius: 50%;
          background-color: darkgray;
          cursor: pointer;

          & > span {
            margin: auto;
          }
        }
      }
    }

    &_details {
      width: 100%;
      p,
      span {
        color: variables.$level-0-color;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        align-self: stretch;
      }

      span {
        color: variables.$slate-gray;
        font-size: 12px;
      }

      &_bar {
        width: 100%;
        height: 1px;
        background-color: #e1e4e8;
        margin-top: 10px !important;
        flex: 1 0 0;

        & > div {
          height: 100%;
        }
      }
      & > div {
        margin-top: 3px;
        color: variables.$slate-gray;
      }
    }
  }

  .active_card {
    background-color: variables.$white;
    border-bottom: 2px solid variables.$dodger-blue;

    .cp_container_binome_details {
      &_bar {
        & > div {
          background-color: variables.$dodger-blue !important;
        }
      }
      & > div {
        color: variables.$dodger-blue;
      }
    }
  }

  .chart_container {
    .custom_legend {
      display: flex;
      align-items: center;
      gap: 10px;

      .legend {
        display: flex;
        padding: 10px;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        flex: 1;

        &_label {
          display: flex;
          align-items: center;
          gap: 10px;
          color: variables.$level-0-color;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 160%;

          &_square {
            width: 10px;
            height: 10px;
            border-radius: 3px;
          }
        }

        &_total {
          display: flex;
          align-items: center;
          align-self: stretch;
          color: variables.$level-0-color;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          gap: 20px;
        }
      }

      .separator {
        width: 2px;
        flex-shrink: 0;
        align-self: stretch;
        background-color: variables.$athens-gray;
      }
    }

    .chart_container_chart {
      display: flex;
      gap: 3px;
    }

    .custom_tooltip {
      display: inline-flex;
      padding: 10px;
      flex-direction: column;
      align-items: flex-start;
      gap: 6px;
      border-radius: 5px;
      border: 1px solid variables.$slate-gray;
      background: variables.$white;
      box-shadow: 0px 4px 10px 0px rgba(41, 57, 77, 0.1);
      width: 188px;

      &_child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1 0 0;
        width: 100%;

        & > div {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .square {
          width: 10px;
          height: 10px;
          border-radius: 3px;
        }
      }
    }
  }

  .divider {
    height: 1px;
    width: 100%;
    background-color: #e1e4e8;
  }

  .non_billable_tasks {
    gap: 5px !important;
    &_head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--dark-1, #29394d);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .counter_bubbles {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    position: relative;
    width: 100%;
    height: 100%;

    .bubble {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: absolute;

      div {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        align-self: stretch;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
      }
    }
  }

  .calendar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
    overflow-y: auto;

    .date {
      color: variables.$level-0-color;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }

    .absence_item {
      display: flex;
      padding: 6px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 5px;
      align-self: stretch;
      border-radius: 5px;
      border: 1px solid var(--gris-2, #e1e4e8);

      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        span {
          color: var(--black-3, #6d7f92);
          text-align: right;
          font-family: Roboto;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        &_child {
          strong {
            color: var(--dark-1, #29394d);
            text-align: center;
            font-family: Roboto;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          div {
            display: flex;
            padding: 3px 6px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 3px;
            font-size: 8px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
          }

          .APPROVED {
            background: var(--vert-inscrit, #edfaf5);
            color: var(--vert-2, #02af8e);
          }
          .PENDING {
            background: var(--jaune, #f7f3e8);
            color: var(--jaune-2, #8c8168);
          }
          .REJECTED {
            border-radius: 3px;
            background: rgba(252, 93, 43, 0.1);
          }
        }

        .formatted_date {
          color: variables.$level-0-color;
          text-align: right;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }

  .error_rapport {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;

    &_column {
      display: flex;
      padding-right: 6px;
      flex-direction: column;
      align-items: center;
      gap: 6px;
      flex: 1 0 0;

      div {
        display: flex;
        padding: 10px 6px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 3px;
        align-self: stretch;
        border-radius: 5px;
        border: 1px solid var(--gris-2, #e1e4e8);
      }
    }
  }

  .balance_container,
  .non_billable_tasks {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 10px;
    position: relative;
    width: 100%;

    h2,
    h3 {
      color: variables.$level-0-color;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    h2 {
      margin-top: 5px;
    }

    h3 {
      font-size: 10px;
    }

    .bar_container {
      position: absolute;
      width: 100%;
      height: 1px;
      border-radius: 3px;
      background: #d8dde2;
      box-sizing: border-box;
      margin-top: 3px;

      .bar, & > div {
        height: 1px;
        border-radius: 3px;
        background-color: variables.$level-0-color;
      }
    }
  }

  .no_data_alert {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 10px;
    color: variables.$level-0-color;
    font-size: 10px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
    margin-top: 30px;
  }
}
