.clients-out-container {
  position: relative;
  margin-bottom: 100px;

  .action-buttons {
    position: fixed;
    bottom: 40px;
    right: 100px;
    display: flex;
    gap: 8px;
    z-index: 1000;

    .p-button {
      width: 120px;

      &-label {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:disabled {
        cursor: not-allowed !important;
        pointer-events: all !important;
      }
    }
  }

  .datatable-header {
    border: none;
    padding-right: 0;
    padding-left: 0;
    background-color: #fafbfb;

    .datatable-header-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      // &:first-of-type {
      //   margin-bottom: 6px;
      //   padding-bottom: 6px;
      //   border-bottom: 1px solid #f1f2f4;
      // }

      &:last-of-type {
        margin-bottom: 12px;
      }
    }

    .filters {
      width: 100%;
    }

    .data-count {
      margin-top: 8px;
      margin: 5px 2px 10px;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #29394D;
      span {
        font-weight: 500;
      }
    }

    .display-info-buttons {
      display: flex;
      justify-content: flex-end;

      .display-info-button {
        background-color: white;
        border: 1px solid #3c4e64;
        padding: 6px 20px;
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
        color: #3c4e64;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        border-radius: 0;
      }

      .display-info-button:hover {
        background-color: #f1f1f1;
      }

      .display-info-button.active {
        background-color: #3c4e64;
        color: #fff;
        border: 1px solid #3c4e64;
      }

      .display-info-button:first-of-type {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-right: none;
      }

      .display-info-button:last-of-type {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-left: none;
      }
    }
  }

  .p-datatable-loading-overlay.p-component-overlay {
    background-color: rgba($color: #000000, $alpha: 0.2);
    border-radius: 12px;
  }

  .p-paginator {
    button {
      width: fit-content;
    }
  }
}

.clients-out-datatable {
  .p-datatable-frozen-tbody {
    tr {
      background-color: #f4f2f2;

      td {
        font-size: 13px !important;
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
    .total-title {
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .p-datatable-thead .datatable-column {
    height: 48px;
    padding: 6px;
    font-size: 10px;
    line-height: 14px;
    background-color: #3c4e64;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin: 0px 1px;
    margin-left: 10px;

    &:hover {
      .p-highlight:hover  {
        background-color: #3c4e64;
        color: white;
      }
    }

    &:first-of-type {
      border-top-left-radius: 8px;
    }

    &:last-of-type {
      border-top-right-radius: 8px;
    }
  }

  .p-sortable-column, .p-sortable-column.p-highlight {
    background-color: #3c4e64;
    color: #fff;
    border: none;

    .p-sortable-column-icon {
      color: white;
    }

    &:not(.p-highlight):not(.p-sortable-disabled):hover {
      background-color: #3c4e64;
      color: #fff;
      border: none;
    }

    &:not(.p-sortable-disabled):hover {
      background-color: #3c4e64;
      color: #fff;
      border: none;

      .p-sortable-column-icon {
        color: white;
      }
    }

    &:hover {
      background-color: #3c4e64;
      color: #fff;
      border: none;

      .p-sortable-column-icon {
        color: white;
      }
    }
  }

  .datatable-column {
    font-size: 14px;
    gap: 10px;

    &:first-of-type {
      border-top-left-radius: 12px;
    }

    &:last-of-type {
      border-top-right-radius: 12px;
    }
  }

  .datatable-cell-input {
    padding: 4px 6px;
    font-size: 12px;
  }

  .p-datatable-tbody tr {

    .date-cell {
      font-size: 12px;
    }

    .client-name-cell {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;

      .client-name {
        text-overflow: ellipsis;
        margin-bottom: 4px;
        font-family: "Roboto";
        font-weight: 500;
        font-size: 14px;
        line-height: 160%;
        display: flex;
        align-items: center;
        color: #29394D;
        text-transform: uppercase;
      }

      .client-other-info {
        display: flex;
        // flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .client-type {
          display: flex;
          align-items: center;
          font-family: "Roboto";
          font-weight: 500;
          font-size: 12px;
          line-height: 160%;
          display: flex;
          align-items: center;
          color: #6D7F92;
        }

        .client-manager-avatar {
          // margin-top: 12px;

          div {
            width: 28px;
            height: 28px;
            margin: 0;
          }
        }
      }
      .edit-texts-icons {
        visibility: hidden;
        display: flex;
        // flex-direction: column;
        gap: 4px;
        justify-content: flex-start;
        // align-items: flex-end;
        transition: visibility .3 linear;
        position: absolute;
        top: 0;
        right: 0;

        .p-button.p-button-icon-only {
          // position: absolute;
          width: 24px;
          height: 24px;
          background-color: #fff;


          .pi {
            font-size: 0.9rem;
          }
        }

        // .icon {
        //   cursor: pointer;
        //   border: 1px solid #18a0fb;
        //   fill: #18a0fb;
        //   border-radius: 100%;
        //   width: 20px;
        //   height: 20px;
        //   padding: 2px;

        //   &:hover {
        //     background-color: #18a0fb;
        //     // color: #f1f1f1;
        //     fill: #fff;
        //   }
        // }
      }
    }

    .centered-cell {
      display: block;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      .edit-texts-icons {
        visibility: visible;
      }
    }

    .presta-to-be-done {
      font-size: 10px;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .presta-to-be-done-item,
      .presta-to-be-done-item-rest {
        width: 130px;
        text-wrap: nowrap;
        border: 1px solid #3c4e64;
        padding: 0 2px;
        border-radius: 4px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .presta-to-be-done-item-rest {
        width: 28px;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .cell-datepicker-input {
    width: 80px;
    background-color: #f1f2f4;
    color: #707f92;
    border: none;
    border-radius: 8px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
  }

  .cell-switch-input {
    width: fit-content;
    &-checked {
      background-color: #3c4e64;
    }
  }

  .cell-input-field {
    .input {
      background-color: #f1f2f4;
      color: #707f92;
      border: none;
      border-radius: 8px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
    }
  }

  .cell-compensation-field {
    display: flex;
    justify-content: center;
    gap: 6px;

    .input {
      background-color: #f1f2f4;
      color: #707f92;
      border: none;
      border-radius: 8px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
    }

    .cell-compensation-field-edit-icon {
      cursor: pointer;
      visibility: hidden;
      width: 11px;
      height: 11px;
      min-width: 11px;
    }

    &:hover {
      .cell-compensation-field-edit-icon {
        cursor: pointer;
        visibility: visible;
      }
    }
  }

  .edit-texts-icons {
    // visibility: hidden;
    display: flex;
    // flex-direction: column;
    gap: 4px;
    justify-content: flex-start;
    // align-items: flex-end;
    // transition: visibility .3 linear;
    // position: absolute;
    // top: 0;
    // right: 0;

    .p-button.p-button-icon-only {
      // position: absolute;
      width: 24px;
      height: 24px;
      background-color: #fff;


      .pi {
        font-size: 0.9rem;
      }
    }

    // .icon {
    //   cursor: pointer;
    //   border: 1px solid #18a0fb;
    //   fill: #18a0fb;
    //   border-radius: 100%;
    //   width: 20px;
    //   height: 20px;
    //   padding: 2px;

    //   &:hover {
    //     background-color: #18a0fb;
    //     // color: #f1f1f1;
    //     fill: #fff;
    //   }
    // }
  }
}
