$red-color: #d50000;
$green-color: #00a000;
$orange-color: #d8a200;

.main_section {
  display: flex;
  gap: 8px;
}

.header_section {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header_filters {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .header_filter_item {
    display: flex;
    align-items: center;
    gap: 16px;

    .filter_name {
      display: inline-block;
      width: 44px;
    }

    .collaborator_select {
      width: 200px;
    }
  }

  .bonus_container {
    padding: 1rem;
    width: fit-content;
    text-align: center;
    display: flex;
    gap: 12px;
  }

  .bonus_text {
    color: #000;
  }

  .bonus_value {
    font-size: 1.4rem;
    font-weight: bold;
  }
}

.column {
  flex-grow: 1;

  // &:first-of-type {
  //   min-width: 500px;
  //   max-width: 500px;
  // }

  &:first-of-type {
    min-width: 390px;
    max-width: 390px;
  }

  &:nth-of-type(2) {
    max-width: 250px;
    min-width: 250px;
  }
  &:nth-of-type(3) {
    min-width: 355px;
    max-width: 355px;
  }

  &:last-of-type {
    width: 380px;
  }
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  width: 380px;
  height: 100%;
  background-color: #fff;
  padding: 4rem 1rem;
  box-shadow: 0px 2px 10px 0px rgba(41, 57, 77, 0.1);
  overflow-y: scroll;

  &_title {
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
  }

  .checks {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .check_item {
    padding: 12px 0.5rem;
    cursor: pointer;
  }

  .check_title {
    display: flex;
    gap: 8px;
    align-items: center;

    .check_label {
      color: #29394D;
      font-size: 0.9rem;
    }

    .check_info_icon {
      margin-left: auto;
    }
  }

  .realized_values {
    color: #3c4e64;
    font-size: 12px;
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .realized_title {
      margin-right: 8px;
    }

    .realized_per_week::after {
      content: '|';
      margin: 6px;
    }
  }

  .check_description {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid rgb(241, 242, 244);
    color: #3c4e64;
    font-size: 12px;
    transition: all 1s ease-in-out;
  }

  .total_remark_text {
    font-size: 10px;
    display: flex;
    align-items: center;
    gap: 3px;

    span {
      color:#d50000
    }
  }
}

.setting_bubbles {
  position: fixed;
  top: 16rem;
  right: -134px;
  color: #fff;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  z-index: 5;
  transition: 0.6s all ease-in-out;

  & > div {
    background: #f8f9fa;
    box-shadow: 0px 4px 5px rgba(60, 78, 100, 0.2);
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    transition: 0.6s all ease-in-out;;
    cursor: pointer;
    position: relative;
    color: #29394D;
    width: 205px;
    height: 62px;
    border-radius: 31px 0px 0px 31px;
    padding: 0 1rem;
    transform: translate(0);

    i {
      font-size: 1.5rem;
      z-index: 2;
      padding: 0 1rem 0 0.3rem;
    }

    span,
    div {
      text-transform: uppercase;
      text-align: center;
      opacity: 0;
    }

    svg {
      fill: #29394D;
      width: 26px;
      height: 24px;
    }

    &.send {
      svg {
        fill: #ffffff;
      }
    }

    &.noFill {
      svg {
        fill: #6D7F92;
      }

      &:hover,
      &.active {
        svg {
          fill: #ffffff;
        }
      }
    }

    &:hover,
    &.active {
      transform: translate(-110px);
      background: #2495e1;
      box-shadow: 0px 4px 5px rgba(109, 127, 146, 0.2);
      color: #fff;

      span,
      div {
        opacity: 1;
      }

      svg {
        fill: #ffffff;
      }

      .padding-left {
        padding-left: 1rem;
      }
    }

    &.opened {
      transform: translate(-650px);
    }

    &.openedExtraSlideSideBar {
      transform: translate(-1050px);
    }

    &.openedAddAnnexeSideBar {
      transform: translate(-950px);
    }

    &.openedAssociationSideBar {
      transform: translate(-67.5vw);
    }

    &.success {
      background: #06d9b1;
      color: #fff;
    }

    &.info {
      background: #f1f2f4;
      color: #3c4e64;

      span {
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}
