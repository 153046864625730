.page_content {
  color: #29394d;
  padding: 0.71rem 4.6%;
  display: flex;
  width: 100%;
  margin: auto;

  .content {
    display: flex;
    width: 100%;

    &_card {
      width: 120px;
      height: 120px;
      padding: 20px;
      border-radius: 5px;
      border: 1px solid #b2bcc6;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-direction: column;
      text-align: center;
      cursor: pointer;
      opacity: 0.7;
      transition: transform 0.3s ease-in-out;
    }

    &_card_active {
      transform: scale(1.07);
      opacity: 1;
      border: 2px solid #18a0fb;
      background-color: #18a0fb10;
    }

    .sub_titles {
      color: #fff;
      width: 18%;
      margin: 11px;
      font-size: 14px;
      line-height: 16px;
      padding: 2rem 0;
      position: relative;

      .step {
        display: flex;
        padding: 10px;
        cursor: pointer;

        .text {
          color: #b2bcc6;
          width: 94%;
          margin-right: 5px;
          padding-bottom: 16px;
          border-bottom: 1px solid rgba(109, 127, 146, 0.3);

          .label {
            font-family: "Roboto";
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-align: right;
            text-transform: uppercase;
            width: 96%;
          }
        }

        .cercle {
          height: 20px;
          width: 20px;
          display: flex;
          justify-content: center;
          background-color: white;
          border: 4px solid #f1f2f4;
          border-radius: 50%;
          filter: drop-shadow(0px 4px 10px rgba(41, 57, 77, 0.1));
          z-index: 5;
          cursor: pointer;

          &.selected {
            background-color: #18a0fb;
            border: 4px solid white;
          }
        }

        span {
          position: absolute;
          margin-top: 17px;
          border-left: 4px solid #d8dde2;
          height: 33px;
          z-index: 1;
        }
      }
    }

    .bloc {
      height: fit-content;
      width: 100%;
      margin: 11px 0 50px;
      position: relative;
      background: #ffffff;
      border: 0.5px solid #f1f2f4;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      border-radius: 5px;
      padding: 24px 28px;

      .title {
        font-family: "Roboto";
        font-weight: 500;
        font-size: 18px;
        text-transform: uppercase;
        color: #29394d;
        margin: 10px 0;
      }

      .description {
        margin-top: 5px;
        font-family: "Roboto";
        font-weight: 400;
        font-size: 12px;
        text-transform: capitalize;
        color: #6d7f92;
      }

      .content {
        display: flex;
        justify-content: center;
        margin: 30px auto;

        .sub_title {
          display: flex;
          flex-direction: column;
          font-family: "Roboto";
          font-weight: 500;
          font-size: 16px;
          text-transform: capitalize;
          color: #29394d;

          span {
            font-size: 12px;
            text-transform: none !important;
            font-weight: 400;
          }
        }

        .client_types {
          margin: 0 auto;
          width: 21%;

          .selected_type {
            background: #18a0fb !important;
            color: white !important;
          }

          .types_labels {
            width: 220px;
            height: 32px;
            background: #f8f9fa;
            color: #6d7f92;
            border-radius: 5px;
            display: flex;
            align-items: center;
            margin: 10px 0;
            font-family: "Roboto";
            font-weight: 500;
            font-size: 12px;
            text-transform: uppercase;
            cursor: pointer;

            .type_label {
              margin: 5%;
            }

            .type_icon {
              margin: 6%;
              margin-left: auto;
            }
          }
        }

        .synchronisation_section {
          margin: 0 auto;
          width: 76%;
          min-height: 300px;
          background: #f8f9fa;
          border-radius: 5px;

          .synchronisation_header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .ts_reminder_btn {
              padding: 0;
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
              height: 44px;
              color: #fff;
              gap: 1px;
              border: 0;
              background-color: transparent;
              cursor: pointer;
              transition: transform .2s ease-in-out;

              &:hover {
                transform: scale(1.01);
              }
          
              span {
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                font-size: 14px;
                margin-left: 5px;
                background-color: #18a0fb;
                border-radius: 5px 0 0 5px;
                height: 100%;
                margin: auto;
                padding: 0 15px;
                text-transform: uppercase;
              }
          
              &_icon {
                height: 100%;
                width: 44px;
                padding: 12px;
                border-radius: 0 5px 5px 0;
                background-color: #18a0fb;
              }
            }
          }

          & > div {
            margin: 2%;
          }
          .params {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 80%;
            margin: auto;

            .months {
              display: flex;
              align-items: center;
              span {
                margin-right: 20px;
              }
              .react-select-container {
                width: 700px !important;
              }
            }
          }
        }
      }
    }
  }
}

.footer_actions {
  margin: 26px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  gap: 10px;

  .button_next,
  .button_prev {
    box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    width: 172px;
    height: 44px;
    border: none;
    font-family: "Roboto";
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
  }

  .button_next {
    background: #06d9b1;
    color: #ffffff;
  }

  .button_prev {
    background: #ffffff;
    color: #6d7f92;
  }
}

.synchronisation_container {
  position: relative;
  width: 100%;
  display: contents;
}
.progress_bar {
  text-align: center;
  padding: 10px;
  // background-color: #f0f0f0;
  border-radius: 4px;
  margin-top: 62px !important;
}
.last_update {
  position: absolute;
  bottom: 100px;
  right: 100px;
  font-size: 14px;
  color: #666;
}

.gradiant_is_runing_synchro {
  border: 1px solid #f1f2f4;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: rgb(238, 238, 238) !important;
  background-image: linear-gradient(
    90deg,
    rgb(220, 220, 220),
    rgb(245, 245, 245),
    rgb(238, 238, 238),
    rgb(220, 220, 220)
  );
  background-size: 300% 100%;
  background-repeat: no-repeat;
  animation: loading 2.5s ease-in-out infinite;
}

@keyframes loading {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
