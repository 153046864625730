@use "../../../styles/base/variables";

$primary-color: #29394d;
$secondary-color: #6d7f92;
$button-background-color: #6d7f92;
$green-color: #02af8e;
$light-green-color: #06d9b1;
$bg-green-color: #edfaf5;
$orange-color: #d87318;
$light-orange-color: #ea8124;
$sky-blue-color: #18a0fb;
$light-sky-blue-color: #c7e8fe;
$red-color: #de4848;
$bg-red-color: #fdefef;
$gray-color: #b2bcc6;
$light-gray-color: #f1f2f4;
$border-color: #e1e4e8;

.container {
  padding: 10px 70px;
  color: $primary-color;
  display: flex;
  flex-direction: column;
  width: 100%;

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    &_salutation {
      display: flex;
      align-items: center;
      gap: 10px;

      .waving_hand {
        display: inline-block;
        transform-origin: bottom center;
        transition: transform 0.2s ease-in-out;
        pointer-events: none;
      }

      &:hover {
        .waving_hand {
          pointer-events: none !important;
          animation: wave 0.5s ease-in-out infinite;
        }
      }

      span {
        display: flex;
        align-items: center;
        gap: 5px;
        color: $primary-color;
        font-size: 16px;

        strong {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          transition: color ease-in-out 0.01s;
        }
      }
    }

    &_filter {
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;
    }
  }

  .main {
    display: flex !important;
    width: 100%;
    box-sizing: border-box;
    gap: 20px;

    // & > div:first-child {
    //   width: 75%;
    // }

    // & > div:last-child {
    //   width: 25% !important;
    // }

    &_stats {
      display: flex;
      flex-direction: column;
      gap: 15px;

      &_stats {
        display: flex;
        flex-wrap: nowrap;
        gap: 10px;

        &_pie_chart {
          position: relative;
          display: flex;
          padding: 0px 10px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          align-self: stretch;
          background-color: variables.$white;
          border: 1px solid variables.$athens-gray;
          border-radius: 5px;

          label {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            color: variables.$level-0-color;
            text-align: center;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            padding: 0 20px;

            strong {
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%;
            }
          }
        }

        &_card {
          position: relative;
          padding: 20px 10px;
          background-color: variables.$white;
          border: 1px solid variables.$athens-gray;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 20px;
          flex: 1 0 0;
          box-sizing: border-box;

          .title {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            color: variables.$level-0-color;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            width: 100%;
          }

          .bar_container {
            position: absolute;
            width: calc(100% - 20px);
            height: 4px;
            border-radius: 3px;
            background: rgba(109, 127, 146, 0.3);
            box-sizing: border-box;

            .bar {
              height: 4px;
              border-radius: 3px;
            }

            &_index {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              font-style: 20px;
              font-weight: 500;
              font-size: 12px;
              line-height: 14px;
              text-align: center;
              svg {
                transform: rotate(180deg);
              }
            }
          }

          .departure_info {
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap: 10px;

            div {
              display: flex;
              padding: 10px 6px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 3px;
              flex: 1 0 0;
              border-radius: 5px;
              border: 1px solid $border-color;
              width: 100%;
              color: var(--dark-1, #29394d);
              text-align: center;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }

          .offshoring_stats {
            display: flex;
            gap: 3px;
            flex-direction: column;
            width: 100%;

            & > div {
              display: flex;
              justify-content: space-between;
            }

            .bar_container {
              position: relative;
              width: 100%;
              height: 1px;
              border: 0;

              .bar {
                height: 1px;
                background: #29394d;
              }
            }
          }
        }

        //   .item_progress {
        //     padding: 20px 10px;
        //     background-color: variables.$white;
        //     border: 1px solid variables.$athens-gray;
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: center;
        //     align-items: center;
        //     gap: 10px;
        //     flex: 1 0 0;

        //     &_info {
        //       display: flex;
        //       justify-content: space-between;
        //       align-items: flex-end;
        //       align-self: stretch;
        //       font-style: normal;
        //       font-weight: 500;
        //       font-size: 12px;
        //       color: variables.$level-0-color;
        //     }

        //     &_container {
        //       width: 250px;
        //       height: 4px;
        //       border-radius: 3px;
        //       background: rgba(109, 127, 146, 0.3);
        //     }
        //     &_bar {
        //       height: 4px;
        //       background: #29394d;
        //       border-radius: 3px;
        //     }
        //     &_index {
        //       display: flex;
        //       align-items: center;
        //       justify-content: center;
        //       flex-direction: column;
        //       font-style: 20px;
        //       font-weight: 500;
        //       font-size: 12px;
        //       line-height: 14px;
        //       svg {
        //         margin: 4px 0 0 0;
        //         transform: rotate(180deg);
        //       }
        //     }
        //   }
      }
    }

    .rh_redirect_card {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;

      h2 {
        color: #29394d;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      & > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;
        width: 100%;

        & > div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
          width: 100%;
          .rh_card {
            display: flex;
            justify-content: space-between;
            width: 100%;
            flex: 1 0 0;

            &_avatar {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;

              & > div {
                display: flex;
                flex-direction: column;
                gap: 3px;

                strong,
                em {
                  color: #29394d;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                }

                em {
                  color: #6d7f92;
                  font-size: 10px;
                }
              }
            }

            &_dev_plan {
              display: flex;
              align-items: center;
              gap: 10px;
              text-align: center;
              font-size: 10px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              color: #29394d;

              &_count {
                display: flex;
                width: 55px;
                height: 28px;
                padding: 10px;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                border: 1px solid #e1e4e8;
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    &_dev_in {
      display: flex;
      align-items: flex-start;
      gap: 20px;
      margin-top: -15px;

      .container {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        gap: 20px;
        padding: 0;
        height: 100%;

        .column {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
          flex: 1 0 0;

          & > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 6px;
            align-self: stretch;
          }
        }

        .divider {
          display: flex;
          width: 1px;
          height: 100%;
          align-self: stretch;
          background: #e1e4e8;
        }
      }

      .remaining_counter {
        display: flex;
        height: 24px;
        justify-content: flex-start;
        align-items: center;
        align-self: stretch;
        color: #6d7f92;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
      }
    }

    .right_column {
      width: calc(25% - 10px);
      // display: flex;
      // flex-direction: column;
      // gap: 20px;

      .redirect_card_progression {
        width: 100%;
        height: auto;

        .p-carousel-content {
          width: 50% !important;
          border-color: red !important;
        }
        &_type {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
          align-self: stretch;

          &_child {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 6px;
            align-self: stretch;

            &_card {
              min-width: 100% !important;
              flex: 1 0 0;
              display: flex;
              padding: 10px 6px;
              align-items: center;
              justify-content: space-between;
              gap: 10px;
              align-self: stretch;
              border-radius: 5px;
              border: 1px solid var(--gris-2, #e1e4e8);
              color: #29394d;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;

              .volum_ecart {
                display: flex;
                width: 40px;
                padding: 3px 6px;
                justify-content: flex-end;
                align-items: center;
                border-radius: 5px;
                font-size: 10px;
              }
            }

            &_info {
              display: flex;
              width: 294px;
              flex-direction: column;
              justify-content: center;
              align-self: stretch;
              color: var(--dark-3, #6d7f92);
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%;
              text-transform: lowercase;
            }
          }
        }
      }

      .redirect_card_annual_report {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-self: stretch;
        gap: 20px;
        transition: 0.3s ease-in;

        &_card {
          width: 100%;
          padding-bottom: 20px;
          border-bottom: 1px solid #e1e4e8;
          transition: 0.3s ease-in;

          &_profile {
            display: flex;
            flex-direction: column;
            gap: 6px;
            align-self: stretch;

            &_info {
              display: flex;
              justify-content: space-between;
              align-items: center;
              align-self: stretch;

              &_avatar {
                display: flex;
                align-items: center;
                gap: 8px;
                position: relative;

                &_avatar {
                  width: 45px;
                  height: 45px;
                  border-radius: 50%;
                  position: relative;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  .progress {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background: conic-gradient(
                      #29394d calc(var(--progress) * 1%),
                      transparent 0%
                    );
                    -webkit-mask: radial-gradient(
                      circle,
                      transparent 61%,
                      #29394d 50%
                    );
                    mask: radial-gradient(circle, transparent 61%, #29394d 50%);
                  }
                }

                div {
                  display: flex;
                  flex-direction: column;

                  strong,
                  em {
                    color: #29394d;
                    align-self: stretch;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                  }

                  em {
                    color: #6d7f92;
                    font-size: 10px;
                  }
                }
              }

              &_diff {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 3px;

                div {
                  display: flex;
                  align-items: center;
                  gap: 6px;

                  span {
                    color: var(--dark-1, #29394d);
                    text-align: right;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                  }
                }
              }
            }

            &_progress {
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;
              gap: 20px;
              align-self: stretch;

              &_details {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 6px;
                flex: 1 0 0;

                & > div {
                  display: flex;
                  align-items: center;
                  gap: 6px;
                  align-self: stretch;

                  span {
                    color: var(--dark-1, #29394d);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                  }
                }

                .va_bar_container {
                  height: 2px;
                  background-color: #b2bcc6;
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;

                  div {
                    height: 2px;
                  }
                }
              }

              &_arrow {
                display: flex;
                width: 24px;
                height: 24px;
                padding: 4px;
                justify-content: center;
                align-items: center;
                border-radius: 100px;
                background: var(--gris-3, #f1f2f4);
                cursor: pointer;
                transition: 0.3s ease-in-out;
              }
            }
          }
        }

        &_details {
          width: 100%;
          padding: 0;
          display: none;
          align-items: center;
          gap: 20px;
          align-self: stretch;
          transition: 0.3s ease-in;
          font-size: 10px;

          &_row {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;

            & > div {
              display: flex;
              justify-content: space-between;
              align-items: center;
              align-self: stretch;
              padding-bottom: 8px;
              border-bottom: 1px solid $primary-color;
            }

            & > div:last-child {
              border-bottom: none;
            }
          }

          &_billable {
            display: flex;
            align-items: center;
            gap: 6px;

            div {
              display: flex;
              width: 75px;
              padding: 10px 6px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              text-align: center;
              align-self: stretch;
              border-radius: 5px;
              border: 1px solid var(--dark-1, #29394d);

              span {
                line-height: 15px;
              }
            }
          }

          .polygon {
            height: 0;
            width: 0;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-left: 10px solid variables.$level-0-color;
          }
        }
      }

      .redirect_card_load_btn {
        display: flex;
        padding: 10px 16px;
        justify-content: center;
        align-items: center;
        align-self: center;
        gap: 12px;
        border-radius: 50px;
        border: 1px solid var(--gris-2, #e1e4e8);
        background: #fff;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        text-transform: uppercase;
        color: var(--dark-1, #29394d);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
      }
    }
  }
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.skeleton * {
  background: #f2f2f2;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.skeleton_profile {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.skeleton_avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.skeleton_info {
  flex: 1;
}

.skeleton_name,
.skeleton_role {
  height: 16px;
  margin-bottom: 8px;
  width: 70%;
}

.skeleton_role {
  width: 50%;
}

.skeleton_progress_bar {
  width: 100%;
  height: 12px;
  margin: 16px 0;
}

.skeleton_progress_arrow {
  width: 24px;
  height: 24px;
  align-self: flex-end;
}

.skeleton_detail_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.skeleton_detail_item {
  width: 30%;
  height: 16px;
}

.skeleton_polygon {
  width: 100%;
  height: 8px;
  margin: 16px 0;
}

.skeleton_billable {
  display: flex;
  justify-content: space-between;
}

.skeleton_billable_item {
  width: 40%;
  height: 16px;
}

/* Add animation */
@keyframes skeleton-shimmer {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.skeleton * {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 50%, #f2f2f2 75%);
  background-size: 200% 100%;
  animation: skeleton-shimmer 1.2s infinite;
}

.skeleton_child {
  margin-bottom: 20px;
  width: 100%;
}

.skeleton_child_card {
  display: flex;
  gap: 10px;
  margin-bottom: 16px;
  padding: 10px;
}

.skeleton_text {
  height: 16px;
  width: 50%;
}

.skeleton_detail {
  display: flex;
  align-items: center;
  gap: 10px;
}

.skeleton_bar {
  height: 12px;
  width: 60px;
}

.skeleton_percentage {
  height: 12px;
  width: 30px;
}

.skeleton_icon {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.skeleton_child_info {
  height: 14px;
  width: 100%;
  margin-top: 10px;
}

/* Add animation for shimmer effect */
@keyframes skeleton-shimmer {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.skeleton * {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 50%, #f2f2f2 75%);
  background-size: 200% 100%;
  animation: skeleton-shimmer 1.2s infinite;
}
