$red-color: #d50000;
$green-color: #00a000;
$orange-color: #d8a200;

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.content {
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  // align-items: center;
  position: relative;

  h4 {
    text-align: center;
  }
}

.data_view_type {
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 12px;
}

.errors_table {
  width: 100%;
  max-width: 100%;
  font-size: 10px;
  font-weight: 500;

  &_cell {
    border: 1px solid #f1f1f2 !important;
  }

  &_paginator {
    font-size: 10px !important;
  }
}
