.deposit_docs_by_document_table,
.deposit_docs_by_client_table {
  font-size: 10px !important;

  th.ant-table-cell {
    font-size: 11px;
    text-transform: capitalize;
    background-color: #f1f1f2;
    padding: 4px;
  }

  .ant-table-cell {
    background-color: #fff;
    color: #000;
    font-size: 11px;
    border-radius: 0;
  }

  .ant-table-row > td {
    background-color: #fff;
    border-top: 1px solid #f1f1f2;
    border-radius: 0;
    padding: 4px;
  }
}

.deposit_docs_by_client_table {
  th.ant-table-cell {
    line-height: 1rem;
  }

  .ant-table-summary {
    .ant-table-cell {
      border-top: 1px solid #f1f1f2;
      text-align: center;
    }
  }
}
