$red-color: #d50000;
$green-color: #00a000;
$orange-color: #d8a200;
$border-color: rgb(241, 242, 244);

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.column_header,
.column_sub_header,
.column_content {
  text-align: center;
  padding: 1rem 0.5rem;
  position: relative;
}

.column_sub_header {
  height: 98px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.column_header {
  .title_value {
    font-weight: bold;
    font-size: 1.5rem;
    color: $red-color;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;

    &_red {
      color: $red-color;
      fill: $red-color;
    }

    &_green {
      color: $green-color;
    }
  }

  .price_tag {
    background-color: #f2f2f2;
    color: #a57b93;
    border-radius: 30px;
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: 12px;
    padding: 0 6px;
  }

  .title {
    color: #000;
    font-size: 14px;
    margin-top: 8px;
  }
}

.column_sub_header {
  .subtitle_value {
    font-weight: bold;
    font-size: 1.2rem;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  .title {
    font-size: 10px;
  }

  .desc {
    font-size: 12px;
    font-weight: 600;
    color: #7b3012;
  }
}

.subtitles {
  display: flex;
  gap: 8px;
}
